/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap'); */

  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #2562B2;
    --secondary-color: #760980;
    --bg-color: #EEF1F5;
    --gradient: linear-gradient(180deg, #29ABE2 0%, #00519F 100%);
    --gradient2: linear-gradient(180deg, #00519F 0%, #29ABE2 100%);
    --gradient3: linear-gradient(180deg, #9f9d9d 0%, #dcdcdc 100%);
    --text-color: #595959;
    --black: #233862;
    --white: #fff;
    --border-radius-10: 10px;
    --border-radius-8: 10px;
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;
}


body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: var(--font-weight-400);
    line-height: 32px;
    color: var(--text-color);
    padding-top: 110px;
}

html,body{
    overflow-x: hidden;
    /* overflow-y: auto; */
  }
.bg {
    background-color: var(--bg-color);
}

a {
    color: var(--text-color);
    text-decoration: none;
}

a:hover {
    color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.btn {
    padding: 20px 35px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 112.1% !important;
    transition: 0.8s !important;
    border: none !important;
    border-radius: 30px !important;
}

.btn.btn-primary {
    background: var(--gradient);
    color: var(--white);
}


.btn-primary {
    background: var(--primary-color);
    color: var(--white);
    border-color: var(--primary-color);
}

.btn.btn-primary:hover {
    background: var(--gradient2);
    color: var(--white);
}




.btn:focus {
    box-shadow: none;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-primary:hover {
    border-color: var(--primary-color);
    background: var(--gradiant-left-to-right);
    color: var(--bg-color);
}


.btn:focus {
    box-shadow: none;
    background-color: var(--gradient2);
}

.btn-primary:hover {
    background: var(--gradient2);
    color: var(--bg-color);
}

.padding-y {
    padding: 100px 0;
}

.heading {
    color: var(--secondary-color);
    font-size: 50px;
    font-weight: var(--font-weight-800);
    line-height: 131.1%;
}

h5 {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

h2, h5 {
    color: #2562b2 ;
    color: var(--primary-color) ;
    font-weight: 700 ;
}

h2 {
    font-size: 40px ;
    line-height: 55px ;
    font-weight: 700 ;
}

p {
    color: var(--text-color);
    font-size: 16px;
    font-weight: var(--font-weight-400);
    line-height: 25px;
}

pre {
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: var(--font-weight-400);
    line-height: 32px;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.py-150 {
    padding: 150px 0;
}

.py-100 {
    padding: 100px 0;
}

h3 {
    color: var(--black);
    font-size: 27px;
    font-weight: var(--font-weight-700);
    line-height: 131.1%;
}

.gradient__left__right {
    background: var(--gradient-left-to-right);
}

.gradient__top__bottom {
    background: var(--gradient-top-to-bottom);
}

/* header css start */
header {
    padding: 15px 0;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    /* background: var(--gradient) !important; */
    /* background-color: #fff; */
    z-index: 99;
}

header.sticky {
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 99;
    height: 110px;
}

header.sticky a.nav-link {
    color: var(--text-color)
}



header.sticky a.nav-link:hover {
    color: var(--primary-color) !important;
}

.form-control:focus {
    border-color: #0e74b8;
    box-shadow: none;
}



header.sticky .logo-white {
    display: none;
}

.logo-colord {
    display: none;
}

header.sticky .logo-colord {
    display: block;
}

ul.navbar-nav.align-items-center li {
    margin-left: 20px;
}

.service-card p {
    font-size: 16px;
    line-height: 25px;
}

.service-card h4 {
    font-weight: 700;
    margin-bottom: 16px;
    color: #000;
}

/* .service-card:hover .services-img img {
    transform: scale(1.1);
} */

.btn-sm {
    padding: 12px 20px !important;
    font-size: 16px !important;
}

.services-img {
    height: 300px;
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
}

.services-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
}

a.nav-link {
    font-size: 18px;
    font-weight: var(--font-weight-500) !important;
    line-height: 112.1%;
    color: var(--white);
}

a.nav-link:hover,
a.nav-link.active {
    color: var(--white) !important;
}

header.sticky a.nav-link:hover,
header.sticky a.nav-link.active {
    color: var(--primary-color) !important;
}

.text-white {
    color: var(--white);
}

/* banner section css */
/* .banner__section {
    background: var(--gradient3);
    background-image: url('../public/assets/images/banner_g.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    padding: 150px 0;
} */

.banner__section {
    background-image: url('../public/assets/images/bnner_tech.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    padding: 150px 0;
}

.contact__section {
    background-image: url('../public/assets/images/map_svg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
    padding: 150px 0;
}
.banner__section h1 {
    font-size: 50px;
    font-weight: var(--font-weight-800);
    line-height: 117.1%;
    color: white;
}
.slider__section h1 {
    font-size: 50px;
    font-weight: var(--font-weight-800);
    line-height: 117.1%;
    color: white;
}

.inquery {
    background-color: #013155;;
}

/* footer */
footer h6 {
    font-size: 18px;
    font-weight: var(--font-weight-700);
    text-transform: uppercase;
    color: var(--primary-color);
}

footer ul li a {
    font-size: 17px;
    font-weight: var(--font-weight-400);
    line-height: 40px;
    transition: 0.3s;
}

footer ul li a:hover {
    transform: translateX(10px);
}

footer {
    background-color: var(--bg-color);
}


/* team css */
.team_div {
    background-color: rgba(255, 0, 0, 0);
    border-radius: 120px;
    padding: 20px 20px;
    margin: 0 10px;
    text-align: center;
}


.team_div img {
    height: 200px;
    max-width: 200px !important;
    border-radius: 50%;
    object-fit: contain;
    margin:  auto ;
    border: 1px solid transparent;
    background-color: white;
}
.client_div {
    background-color: rgba(255, 0, 0, 0);
    border-radius: 120px;
    /* padding: 20px 20px 70px; */
    /* margin: 0 10px; */
    text-align: center;
}
.client_div img {
    height: 150px;
    max-width: 150px !important;
    /* border-radius: 50%; */
    object-fit: contain;
    margin: 0 auto 50px;
    border: 1px solid transparent;
}

.team_div h6 {
    color: var(--black);
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}

/* .team_div:hover {
    background: var(--gradient);
    color: var(--white);
} */

.team_div:hover img {
    border-color: var(--white);
}

.team_div:hover h6,
.team_div:hover p {
    color: var(--white);
}

/* our_company */
.our_company {
    /* background-image: url('../images/pages/home/our-company.png'); */
    background-color: #00519f;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

/* card */
.support_card {
    border-radius: 5px;
    background: var(--white);
    box-shadow: 0px 0px 14px 0px rgba(37, 98, 178, 0.25);
    padding: 40px;
    text-align: left;
    transition: 0.3s;
}

.support_card:hover {
    transform: translateY(-10px);
}

.support_card h6 {
    color: var(--black);
    /* font-family: Open Sans; */
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.pt-80 {
    padding-top: 80px;
}

/*========= Nayan ==========*/

.btn_shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}



    .blur_background {
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 5px;
        color: #fff;
        font-family: sans-serif;
        line-height: 1.5;
        padding: 1rem 0rem;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(1px);
        background-color: rgb(255 255 255 / 70%);
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    .blur_background  .form-label{
  color: #2d2d2d !important
    }
    .contact--icon__style i{
        color: white
    }
    .contact--icon__style {
        height: 50px;
        width: 50px;
        border-radius: 25px;
        background: var(--gradient);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 15px;
        
    }

    /* .blur_background p {
        color: white
    }
    .blur_background h5 {
        color: white
    } */




/*========================= Second Code ========================================*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

:root {
    --primary-color: #2562b2;
    --secondary-color: #760980;
    /* --bg-color: #F5F1F5; */
    --bg-color: #eef1f5;

    --gradient-left-to-right: linear-gradient(86deg, #76097F 22.66%, #EF410F 100%);
    --gradient-top-to-bottom: linear-gradient(180deg, #760980 0%, #E83E16 100%);
    --text-color: #444;
    --black: #202020;
    --white: #fff;
    --border-radius-10: 10px;
    --border-radius-8: 10px;
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;
}

footer .social__links img {
    width: 35px;
}

/* a.navbar-brand {
    width: 25%;
} */

.bg2 {
    background: rgba(255, 245, 255, 0.66);
}



.bg {
    background-color: var(--bg-color);
}

a {
    color: var(--text-color);
    text-decoration: none;
}

a:hover {
    color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.padding-y {
    padding: 100px 0;
}

.heading {
    color: var(--secondary-color);
    font-size: 50px;
    font-weight: var(--font-weight-800);
    line-height: 131.1%;
}

.gradinat-img {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--gradient);
    z-index: 1;
}

h3 {
    color: var(--black);
    font-size: 22px;
    font-weight: var(--font-weight-700);
    line-height: 131.1%;
}

.gradient__left__right {
    background: var(--gradient-left-to-right);
}

.gradient__top__bottom {
    background: var(--gradient-top-to-bottom);
}

/* header css start */
header {
    padding: 15px 0;
}

a.nav-link {
    font-size: 14px;
    font-weight: var(--font-weight-400);
    line-height: 305.1%;
    color: var(--text-color);
}

a.nav-link:hover,
a.nav-link.active {
    color: var(--primary-color) !important;
}


.banner__section h1 {
    font-size: 50px;
    font-weight: var(--font-weight-800);
    line-height: 117.1%;
    color: white;
}

.banner__subtitle {
    color: white;
    font-size: 16px;
    font-weight: 600;
}

/* about section */
.about__section {
    /* background-image: url('../images/about-banner.png'); */
    /* background-image: url('./assets/images/about-banner.png'); */
    background-color: #0F1935;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top left;
    padding: 120px 0;
}

.contact__card {
    padding: 30px;
    background-color: var(--white);
    border-radius: var(--border-radius-10);
    height: 100%;
}

.contact__icon img {
    width: 25px;
}

.contact__icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-brand img {
    max-width: 100%;
}

/* footer */
footer h6 {
    font-size: 18px;
    font-weight: var(--font-weight-700);
    text-transform: uppercase;
}

footer ul li .nav-link {
    font-size: 14px;
    font-weight: var(--font-weight-400);
    line-height: 40px;
    transition: 0.3s;
}

footer ul li a:hover {
    transform: translateX(10px);
}

footer {
    background-color: var(--bg-color);
}

/* testmonial css start */
.testmonial__main {
    padding: 60px;
    background-color: var(--white);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
}

.testmonial__main p {
    color: var(--black);
    font-size: 36px;
    font-weight: var(--font-weight-700);
    line-height: 48px;
}

.testmonial_img img {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 9;
}

.testmonial_img {
    padding: 20px;
    position: relative;
    z-index: 2;
}

.testmonial_img::after {
    height: 100%;
    width: 70%;
    content: '';
    background: var(--gradient-top-to-bottom);
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.testmonial_img::before {
    content: '';
    width: 68%;
    height: 94%;
    background-color: #fff;
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.testmonial__section::before {
    width: 37%;
    height: 93%;
    position: absolute;
    top: 50%;
    left: 39px;
    transform: translateY(-50%);
    content: '';
    background-color: #f5f1f5;
}

.testmonial__section {
    position: relative;
    padding: 80px 100px;
}

.testmonial__section::after {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    height: 100%;
    border-radius: 8px;
    width: 40%;
    background: var(--gradient-top-to-bottom);
    z-index: -1;
}

/* owl slidre */
.testmonial__owl .owl-dots button {
    display: block !important;
}

.testmonial__owl .owl-dots {
    top: 50%;
    position: absolute;
    right: 9.5%;
    transform: translateY(-50%);
}

.testmonial__owl.owl-theme .owl-dots .owl-dot.active span,
.testmonial__owl.owl-theme .owl-dots .owl-dot:hover span {
    background: var(--primary-color);
}

.testmonial__owl .owl-nav {
    position: absolute;
    bottom: 19%;
    left: 14%;
}

.page_warp {
    min-height: calc(100vh - 460px);
}

/*  */
.top_heading {
    /* background: var(--gradient-left-to-right); */
    /* background: linear-gradient(-45deg, #760b7c, #a01c58, #c43640, #fe5d04); */
    background: linear-gradient(-45deg, #29abe2, #30aee3, #157fc1, #00519f);
    padding: 60px 0;
    background-size: 400% 400%;
    animation: gradient 6s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.team__content {
    background-color: var(--bg-color);
    padding: 20px;
}

.team__content p {
    font-size: 18px;
    font-weight: var(--font-weight-400);
    line-height: 140%;
    margin-bottom: 10px;
}

.team__content h6 {
    color: #2562b2;
    font-size: 18px;
    font-weight: var(--font-weight-700);
    letter-spacing: 2.88px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.team__img img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: 0.3s;
}

img {
    max-width: 100%;
}

.team__img {
    width: 100%;
    max-height: 300px;
    overflow: hidden;
}

.team__main:hover .team__img img {
    transform: scale(1.1);
}

.partner__main img {
    height: 100px;
    width: auto;
    transition: 0.3s;
    object-fit: contain;
}

.gradiant {
    background: var(--gradient);
}

.partner__main {
    text-align: center;
    /* border: 1px solid #ccc; */
    padding: 5px;
    background-color: var(--white);
    height:150px;
    aspect-ratio: 1/1;
}

.form-label {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 20px;
}
.inquery .form-label {
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 20px;
    color: white;
}

.page-warp {
    min-height: calc(100vh - 452px);
}

.partner__main:hover img {
    transform: scale(0.9);
}

.blog__section {
    border-radius: 16px;
    border: 1px solid #DEDEDE;
    background: var(--white);
    padding: 20px;
}

.blog__section span {
    font-size: 12px;
    font-weight: var(--font-weight-400);
    display: inline-block;
    margin-bottom: 10px;
}

.blog__section h6 {
    color: var(--primary-color);
    font-size: 26px;
    font-weight: var(--font-weight-600);
    line-height: 36px;
    margin-bottom: 10px;
}

.blog__section p {
    color: var(--text-color);
    font-size: 16px;
    font-weight: var(--font-weight-400);
    line-height: 28px;
    margin-bottom: 10px;
}

.blog__section .read_btn {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

.blog__section .read_btn img {
    filter: brightness(0);
}

.pe-80 {
    padding-right: 80px;
}

.blog__section:hover .blog__img img {
    transform: scale(1.1);
}

.blog__img {
    min-height: 250px;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
}

.blog__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
}

.contactpage p {
    /* font-size: 16px; */
    line-height: 30px;
}

.contactpage h1 {
    font-size: 40px;
    font-weight: 700;
    color: var(--primary-color);
}

.form-control {
    padding: 15px 15px;
    border-radius: 10px;
}

textarea.form-control {
    border-radius: 10px;
}

.search_div img {
    position: absolute;
    top: 20px;
    right: 15px;
}

span.date-time {
    font-size: 15px;
    color: #848282;
}

.resent_blog a {
    color: #292727;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    display: block;
    padding-right: 30px;
    margin-bottom: 25px;
    position: relative;
}

.resent_blog a img {
    position: absolute;
    top: 16px;
    right: 0;
    width: 16px;
}

.resent_blog h5 {
    color: #0F1935;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.resent_blog a:hover {
    color: var(--primary-color);
}

/* .about__section */


.service_card {
    border-radius: 9px;
    background: #FFF;
    padding: 20px 20px 50px;
}

.service_img {
    padding: 15px;
    display: inline-block;
    margin-bottom: 20px;
}

.service_img img {
    height: 30px;
}

.service_card h6 {
    color: #2F2F2F;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.service_card p {
    color: #2F3241;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.service_card a img {
    width: 15px
}

.service_card a:hover {
    color: #000 !important;
}

.service_card a:hover img {
    filter: brightness(0);
}

.service_card a {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 500;
}

.our-misstion-div {
    background-color: var(--secondary-color);
    padding: 80px 70px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.our-misstion-div::after {
    content: '';
    position: absolute;
    bottom: -18%;
    right: -10%;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #ffffff26;
    animation: 3s move2 infinite;
}

.our-misstion-div::before {
    content: '';
    position: absolute;
    bottom: 11%;
    right: 13%;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    transition: 0.3s;
    background-color: #ffffff26;
    animation: 3s move infinite;
}

@keyframes move {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes move2 {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.misstion_img {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
}

.misstion_img1 {
    height: 75%;
}

.misstion_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
}

.misstion_img:hover img {
    transform: scale(1.1);
}

.misstion_img2 {
    height: 48%;
}

.abut__section_img {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
}

.shade_section {
    position: relative;
    overflow: hidden;
}

.shade-top {
    opacity: 0.10000000149011612;
    background: #DB14F1;
    filter: blur(100px);
    height: 400px;
    width: 400px;
    border-radius: 50%;
    position: absolute;
    top: -10%;
    left: -10%;
}

.shade-bottom {
    opacity: 0.10000000149011612;
    background: #DB14F1;
    filter: blur(100px);
    height: 400px;
    width: 400px;
    border-radius: 50%;
    position: absolute;
    bottom: -10%;
    right: -10%;
}

.top_icon {
    position: fixed;
    right: 20px;
    bottom: 20px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: var(--gradient-top-to-bottom);
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
}

.top_icon {
    opacity: 1;
    visibility: visible;
}





/*==================== js ===================  */

.blog_details_TLA {
    text-align: justify;
}

.active {
    color: red !important;
    font-weight: bold !important;
}

.banner__section button {
    background: #fff !important;
    color: #0155a2 !important;
}
.slider__section button {
    background: #fff !important;
    color: #0155a2 !important;
}

.slider__section button:hover {
    background-color: #29abe2 !important;
    color: #fff !important;
}
.banner__section button:hover {
    background-color: #29abe2 !important;
    color: #fff !important;
}

.testmonial-div {
    padding: 50px;
    border-radius: 20px;
    margin: 0 20px;
}

.testmonial-div .qout {
    width: 30px;
}

.testmonial-div .client-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.testmonial-div p {
    font-size: 16px;
    line-height: 30px;
}

.text_jus {
    text-align: justify;
  text-justify: inter-word;
}
.social__icons {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}
.social__icons button {
    height: 40px;
    width: 40px;
    border-radius: 25px !important;
    padding: 5px !important;
    background: var(--gradient);
}
.social__icons button:hover {
    height: 40px ;
    width: 40px ;
    border-radius: 25px ;
    padding: 5px;
    background: var( --gradient2);
    transition: all 0.3s ease-in-out;
}

.social_media_icon {
    height:25px;
    width: 25px;
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

footer link {
    font-size: 0.875rem;
}
.terms ol li {
    font-weight: 500;
    text-align: justify;
}

.terms li ol li {
    font-weight:normal ;
}

.terms br {
    display: block;
}
.contact__us-hover p:hover {
    transform: translateX(10px);
    color: #00519F;
    transition: all 0.3s ease-in-out;
}
.carousel-indicators {
    display: none !important;
}

.verticle_icon img {
        -webkit-filter: invert(100%); 
        filter: invert(100%);
        height:50px;
        width: 50px;
        

}
.verticle_icon {
    height: 100px;
    width: 100px;
    border-radius: 30px;
    background-color: #00519F;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    object-fit: contain;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}


/*=========================================*/

.slick-next:before {
    content: '→';
    color: var(--primary-color) !important;
}
.slick-prev:before {
    content: '←';
    color: var(--primary-color) !important;
}

/*+++++++++++++++++++++++++++++++++++++++++*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .banner__section br {
        display: none;
    }
    .banner__section h1 {
        font-size: 40px;}
        
    .slider__section h1 {
        font-size: 40px;}
      
        header.sticky {
            height: 60px;
        }

        body {
          
            padding-top: 60px;
        }

        .contactpage  p {
            font-size: 14px;
        }

        .testmonial-div p {
            font-size: 14px;
            line-height: 20px;
        }

        .testmonial-div h5 {
            font-size: 18px;
        }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .banner__section br {
        display: block;
    }
    .banner__section h1 {
        font-size: 60px;}

    .slider__section h1 {
        font-size: 60px;}
        
        .banner__subtitle {
            color: white;
            font-size: 16px;
            font-weight: 600;
        }
        .slider__subtitle {
            color: white;
            font-size: 16px;
            font-weight: 600;
        }
    
        .logo-size {
            width: 100%;
        }

        /* header.sticky {
            height: 60px;
        } */

        body {
            padding-top: 60px;
        }

        .contactpage  p {
            font-size: 14px;
        }

        .testmonial-div p {
            font-size: 14px;
            line-height: 20px;
        }

        .testmonial-div h5 {
            font-size: 20px;
        }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    
    .banner__section h1 {
        font-size: 60px;}

    .slider__section h1 {
        font-size: 60px;}

        .slider__subtitle {
            color: white;
            font-size: 16px;
            font-weight: 600;
        }
     

        .logo-size {
            width: 60%;
        }

        header.sticky {
         
            height: 110px;
        }

        body {
            padding-top: 100px;
        }

        .contactpage p {
            font-size: 14px;
        }

        .testmonial-div p {
            font-size: 16px;
            line-height: 30px;
        }
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .banner__section h1 {
        font-size: 72px;}
        .slider__section h1 {
            font-size: 72px;}
}
 


.contactpage p {
    font-size: 14px;
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}
/*+++++++++++++++++++++++++++++++++++++++++*/

.carousel-caption {
    position: absolute;
    right: 15%;
    /* bottom: 1.25rem; */
    top: 30%;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

.listing ul li {
    list-style: circle;
    list-style: outside;
    font-size: 14px;
    text-align: justify;
}

.header_font_size {
    font-size: 12px;
}